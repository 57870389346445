/** @jsxImportSource theme-ui */
import React from "react";

// components
import TransButton from "./trans-button";
import ContactLink from "../micro-components/contact-link";
import SocialList from "../micro-components/social-list";

// style
import "../style/footer.scss";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-catch-container" sx={{ color: "primary" }}>
        <p>Creiamo qualcosa di nuovo insieme</p>
        <p>Contattami</p>
      </div>
      <div className="footer-contact-container">
        <p>Informazioni di contatto</p>
        <ul>
          <li>
            <ContactLink
              to="mailto: contact@giordanobetti.com"
              title="Invia una mail a Giordano Betti"
            >
              contact@giordanobetti.com
            </ContactLink>
          </li>
          <li>
            <ContactLink
              to="tel: +39 331 891 2895"
              title="Chiama Giordano Betti"
            >
              +39 331 891 2895
            </ContactLink>
          </li>
          <li>
            <span>Via Cappella 23, Sinalunga, Siena.</span>
          </li>
        </ul>
      </div>
      <div className="footer-links-container">
        <p>Shortcuts</p>
        <ul>
          <li>
            <TransButton to="/portfolio/avavav" type="default">
              MFW AVAVAV
            </TransButton>
          </li>
          <li>
            <TransButton to="/portfolio/non-solo-muse" type="default">
              Intervista per Oxford University
            </TransButton>
          </li>
        </ul>
      </div>
      <div className="footer-social-container">
        <SocialList dark={true} />
      </div>
      <div className="footer-info-container">
        <p>P.iva 01520740521</p>
        <p className="copyright">
          Giordano Betti &copy; {new Date().getFullYear()}
        </p>
        <p>
          <ContactLink
            to="https://www.iubenda.com/privacy-policy/89672123"
            title="Pagina privacy policy di Iubenda"
            external={true}
          >
            Privacy / Cookie Policy
          </ContactLink>
        </p>
        <p>
          <ContactLink
            to="https://www.dylanbartoli.com"
            title="Website credits"
            external={true}
          >
            Credits
          </ContactLink>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
