/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react'
import gsap from 'gsap'

// components
import Header from '../components/header'
import Footer from '../components/footer'
import CookieConsent from 'react-cookie-consent'
import Mouse from '../utils/mouse'

// functions
import { showBonjur } from '../functions/showBonjur'
import { isLoaded } from '../functions/isLoaded'
import { hideLoader } from '../functions/hideLoader'
import { isMobile } from 'react-device-detect'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { useLocation } from '@reach/router'

// styles
import '../style/reset.css'
import '../style/locomotive.css'
import '../style/loader.scss'
import '../style/global.scss'
import TransButton from '../components/trans-button'

const Layout = ({ children }) => {
  const location = useLocation()

  const [POPLocation, setPOPLocation] = useState('/')

  useEffect(() => {
    // refresh page on POP event
    window.onpopstate = function () {
      if (window.location.pathname !== POPLocation) {
        setPOPLocation(window.location.pathname)
      }
      document.querySelector('.POP-button-reloader').click()
    }
  }, [POPLocation])

  useEffect(() => {
    setPOPLocation(window.location.pathname)

    // set overflow for locomotive scroll based on the device
    if (!isMobile) {
      document.querySelector('body').style.overflow = 'hidden'
      document.querySelector('.scroll-section').style.perspective = '1px'
    }

    // get loading status
    const targetMain = document.querySelector('[main-visible-status]')

    // create async call to wait media to load on first page load
    const waitToLoad = async () => {
      await Promise.all([showBonjur(), isLoaded()])
      gsap.to(document.querySelector('.welcome-msg span'), {
        yPercent: -400,
        duration : .5,
        delay : 0,
        ease : 'expo.in'
      })
      hideLoader()

      // timeout to set the page visible
      setTimeout(() => {
        targetMain.setAttribute('main-visible-status', 'visible')
      }, 500)
    }

    // call async call
    waitToLoad()
  }, [])

  return (
    <>
      <Header />
      <div className='scroll-section'>
        <main main-visible-status='hidden'>{children}</main>
        <Footer />
      </div>
      <div id='loader' sx={{ bg: 'secondary' }}>
        <div className='loader-text'>
          <p className='welcome-msg'>
            <span>
              {new Date().getHours() < 13 ? 'Buongiorno' : 'Buonasera'}
            </span>
          </p>
          <p className='loader-perc'>0</p>
        </div>
        <div sx={{ bg: 'primary' }} className='veil'></div>
      </div>
      <TransButton
        to={POPLocation}
        type='none'
        className='POP-button-reloader'
      ></TransButton>
      <CookieConsent
        location='bottom'
        buttonText='Ho capito'
        cookieName='gatsby-gdpr-google-analytics'
        expires={150}
        sameSite='none'
        disableStyles
        hideOnAccept={false}
        onAccept={() => {
          gsap.to(document.querySelector('.cookie-banner'), {
            yPercent: 200,
          })
          initializeAndTrack(location)
        }}
        containerClasses='cookie-banner shadow-black'
        buttonClasses='cookie-banner-accept'
        contentClasses='cookie-banner-text'
      >
        <p>
          Questo sito utilizza i cookie per migliorare l'esperienze dell'utente.
        </p>
      </CookieConsent>
      <Mouse />
    </>
  )
}

export default Layout
