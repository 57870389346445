/** @jsxImportSource theme-ui */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'

// functions
import { useLocation } from '@reach/router'
import { isLoaded } from '../functions/isLoaded'
import { showLoader } from '../functions/showLoader'
import { hideLoader } from '../functions/hideLoader'

// components
import { TransitionLink } from 'gatsby-plugin-transition-link/components/TransitionLink'

// style
import * as style from '../style/trans-button.module.scss'

const TransButton = ({
  to = '/',
  type = 'menu',
  className = null,
  children,
}) => {
  const button = useRef()

  const location = useLocation().pathname

  const isActive = () => {
    if (to !== '/') {
      if (location.search(to) === 0) return true
      else return false
    } else {
      if (to === location) return true
      else return false
    }
  }

  const checkSamePage = () => {
    if (to === location) return true
    else return false
  }

  useEffect(() => {
    if (type !== 'none') {
      // get button's span
      const spans = button.current.querySelectorAll('span')

      // define the animation
      const tl = gsap.timeline({
        paused: true,
        defaults: { ease: 'expo.inOut', duration: 0.5 },
      })
      tl.to(spans[0], {
        yPercent: -200,
      }).fromTo(
        spans[1],
        {
          yPercent: 90,
        },
        {
          yPercent: 0,
        },
        0
      )

      // attach event listener to button
      button.current.addEventListener('mouseenter', () => tl.play())
      button.current.addEventListener('mouseleave', () => tl.reverse())
    }
  }, [type])

  return (
    <TransitionLink
      to={to}
      replace
      state={{ prevPath: location.pathname }}
      exit={{ length: 1 }}
      entry={{ delay: 1 }}
      trigger={async (pages) => {
        gsap
          .timeline()
          .to(button.current, {
            scale: 0.95,
            duration: 0.25,
            ease: 'back.out',
          })
          .to(button.current, {
            scale: 1,
            duration: 0.25,
            ease: 'back.out',
          })

        const targetMain = document.querySelector('[main-visible-status]')
        targetMain.setAttribute('main-visible-status', 'hidden')

        if (!checkSamePage()) {
          showLoader()
          console.log('entered function')
          window.history.pushState('', '', to)
          // wait until we have access to both pages
          await pages.exit
          console.log('exited')
          const entry = await pages.entry
          console.log('entered new page')
          // here we can access both pages

          // start exit animation based on measurements if you want
          // wait for the entering page to become visible
          await entry.visible
          console.log('visible')
          // call the loaded function when the page is entered
          await isLoaded()
          console.log('loaded')
          hideLoader()

          // timeout to set the page visible
          setTimeout(() => {
            targetMain.setAttribute('main-visible-status', 'visible')
          }, 500)
        } else {
          // fake reloading of same page
          showLoader()
          setTimeout(() => {
            hideLoader()
          }, 850)
          // timeout to set the page visible
          setTimeout(() => {
            targetMain.setAttribute('main-visible-status', 'visible')
          }, 1250)
        }
      }}
      className={`${
        type === 'menu'
          ? style.button + ' shadow-black'
          : type === 'decorative'
          ? style.linkDecorative
          : type === 'img'
          ? null
          : style.link
      } ${className}`}
      sx={{
        borderColor: type === 'decorative' ? 'primary' : 'dark',
      }}
      ref={button}
    >
      {type === 'img' ? '' : <span>{children}</span>}
      {type === 'none' || type === 'img' ? (
        ''
      ) : (
        <span
          sx={{
            color:
              isActive() && type === 'menu'
                ? 'light'
                : type !== 'menu'
                ? 'primary'
                : 'dark',
            bg: isActive() && type === 'menu' ? 'primary' : 'transparent',
          }}
          className={style.lastSpan}
        >
          {children}
        </span>
      )}
    </TransitionLink>
  )
}

export default TransButton

TransButton.propTypes = {
  to: PropTypes.string,
}
