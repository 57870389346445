/** @jsxImportSource theme-ui */
import React, { useEffect, useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'

// functions
import { useLocation } from '@reach/router'
import { isLoaded } from '../functions/isLoaded'

// components
import { TransitionLink } from 'gatsby-plugin-transition-link/components/TransitionLink'

// const button = React.forwardRef(( to, setState, children, ref ) => ()

const MenuTransButton = forwardRef(({ to, setState, children }, ref) => {
  const link = useRef()

  const location = useLocation().pathname

  const isActive = () => {
    if (to !== '/') {
      if (location.search(to) === 0) return true
      else return false
    } else {
      if (to === location) return true
      else return false
    }
  }

  const checkSamePage = () => {
    if (to === location) return true
    else return false
  }

  useEffect(() => {
    const span1 = link.current.querySelector('.first-span')
    const span2 = link.current.querySelector('.last-span')

    const spans1 = span1.querySelectorAll('span')
    const spans2 = span2.querySelectorAll('span')

    const tl = gsap.timeline({
      paused: true,
      defaults: {
        stagger: 0.05,
        ease: 'expo.inOut',
      },
    })
    tl.to(spans1, {
      yPercent: -100,
    }).fromTo(
      spans2,
      {
        yPercent: 150,
      },
      {
        yPercent: 0,
      },
      0
    )

    link.current.addEventListener('mouseenter', () => tl.play())
    link.current.addEventListener('mouseleave', () => tl.reverse())
  }, [])

  return (
    <TransitionLink
      to={to}
      exit={{ length: 1 }}
      entry={{ delay: 1 }}
      trigger={async (pages) => {
        const menuElements = document.querySelectorAll(
          '.menu-container > *:not(.menu-load-allert)'
        )
        const targetMain = document.querySelector('[main-visible-status]')
        targetMain.setAttribute('main-visible-status', 'hidden')

        document
          .querySelector('.menu-container')
          .setAttribute('menu-status', 'close')
          
        if (!checkSamePage()) {
          const tl = gsap.timeline({})
          tl.to(menuElements, {
            autoAlpha: 0,
            scale: 0.75,
            duration: 1.25,
            stagger: {
              amount: 0.5,
            },
            ease: 'expo.inOut',
          })
          // wait until we have access to both pages
          await pages.exit
          const entry = await pages.entry
          // here we can access both pages

          // start exit animation based on measurements if you want
          // wait for the entering page to become visible
          await entry.visible

          // call the loaded function when the page is entered
          await isLoaded()
          setState(true)

          setTimeout(() => {
            ref.current.reverse()
          }, 250)

          setTimeout(() => {
            targetMain.setAttribute('main-visible-status', 'visible')
            tl.to(menuElements, {
              clearProps: 'all',
            })
          }, 750)
        } else {
          ref.current.reverse()
          setState(true)

          setTimeout(() => {
            targetMain.setAttribute('main-visible-status', 'visible')
          }, ref.current.duration() * 1000 - 250)
        }
      }}
      ref={link}
      sx={{
        color: isActive() ? 'primary' : 'inherith',
      }}
    >
      {children}
    </TransitionLink>
  )
})

export default MenuTransButton

MenuTransButton.propTypes = {
  to: PropTypes.string,
}
