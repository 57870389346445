import gsap from 'gsap'

// sleep const to wait some time
const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

const loadTl = gsap.timeline({})

let percentage = 0
let actualPerc = 0
let videoPerc = 0
let media = 0

// wait video to load
function isVideoLoaded(video) {
  return new Promise((loaded) => {
    video.onloadeddata = () => {
      actualPerc = percentage
      percentage += videoPerc

      const counter = gsap.to(document.querySelectorAll('.loader-perc'), {
        textContent: percentage,
        duration: 0.25,
        snap: {
          textContent: 1,
        },
        stagger: {
          onUpdate: function () {
            this.targets()[0].innerHTML = Math.ceil(
              this.targets()[0].textContent
            ) + '%'
          },
        },
      })

      loadTl.add(counter)

      loaded(true)
    }
  })
}

// main function to select all video and wait them to load
export async function isLoaded() {
  return new Promise(async (resolve) => {
    // sleep 1 ms to fix an android DOM load content
    sleep(1).then(async () => {
      media = document.querySelectorAll('video')
      document.querySelectorAll('.loader-perc').forEach(elem => elem.innerHTML = '0%')

      videoPerc = 100 / media.length

      if (media.length > 0) {
        // wait for all video to be loaed
        const showLoader = gsap.to(document.querySelectorAll('.loader-perc'), {
          autoAlpha: 1,
          duration : .5,
          stagger : 0
        })
        loadTl.add(showLoader)
        await Promise.all(
          Array.from(media).map(async (video) => {
            // wait for a single video to be loaded before others
            await isVideoLoaded(video)
          })
        )
      }

      // to counter 0
      const counter = gsap.to(document.querySelectorAll('.loader-perc'), {
        autoAlpha: 0,
        duration: 0.5,
        stagger : 0
      })

      if (media.length > 0) 
        loadTl.call(() => resolve(true)).add(counter)
      else resolve(true)

      percentage = 0
      actualPerc = 0
      videoPerc = 0
      media = 0
    })
  })
}
