import gsap from 'gsap'

export function showLoader() {
  const loader = document.querySelector('#loader')

  const veil = loader.querySelector('.veil')

  const loaderTl = gsap.timeline({
    defaults: {
      ease: 'expo.out',
      duration: 0.75,
    },
  })

  loaderTl
    .set(loader, { transformOrigin: 'center left' })
    .set(veil, { transformOrigin: 'center left' })
    .to(loader, { scaleX: 1 })
    .to(veil, { scaleX: 1 }, 0)
}
