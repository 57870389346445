const theme = {
  config: {
    useColorSchemeMediaQuery: false,
    initialColorModeName: 'bittersweet',
  },
  colors: {
    light: '#e7e5df',
    dark: '#393e41',

    primary: '#ed6a5e',
    secondary: '#eaa89f',
    
    modes: {
      viridian: {
        primary: '#119da4',
        secondary: '#7cc1c2',
      },
      caryola: {
        primary: '#8783d1',
        secondary: '#b7b4d8',
      },
    },
  },
}

export default theme
