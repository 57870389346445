/** @jsxImportSource theme-ui */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'

// style
import * as style from '../style/contact-link.module.scss'

const ContactLink = ({
  to = '/',
  title = '',
  external = false,
  dark = false,
  type = 'default',
  children,
}) => {
  const link = useRef(null)

  useEffect(() => {
    const spans = link.current.querySelectorAll('span')

    const tl = gsap.timeline({
      paused: true,
      defaults: { ease: 'expo.inOut', duration: .5 },
    })
    tl.to(spans[0], {
      yPercent: -200,
    }).fromTo(
      spans[1],
      {
        yPercent: 90,
      },
      {
        yPercent: 0,
      },
      0
    )

    link.current.addEventListener('mouseenter', () => tl.play())
    link.current.addEventListener('mouseleave', () => tl.reverse())
  }, [])
  return (
    <a
      href={to}
      title={title}
      target={external ? '_blank' : ''}
      rel='noreferrer'
      className={type === 'decorative' ? style.linkDecorative : style.link}
      ref={link}
    >
      <span
        sx={{
          color: dark ? 'light' : 'dark',
        }}
      >
        {children}
      </span>
      <span
        sx={{
          color: dark ? 'secondary' : 'primary',
        }}
      >
        {children}
      </span>
    </a>
  )
}

export default ContactLink

ContactLink.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  external: PropTypes.bool,
  type: PropTypes.string
}
