import gsap from 'gsap'

// main function to select all video and wait them to load
export async function showBonjur() {
  return new Promise(async (resolve) => {
    const tl = gsap.timeline({
      onComplete: () => {
          resolve(true)
        },
    })
    tl.to(document.querySelector('.welcome-msg span'), {
      yPercent: -200,
      duration : .75,
      delay : .5,
      ease : 'expo.out'
    })
  })
}
