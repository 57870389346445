/** @jsxImportSource theme-ui */
import React from 'react'
import PropTypes from 'prop-types'

// style
import * as style from '../style/social-list.module.scss'

const SocialList = ({ className = null, dark = false }) => {
  return (
    <ul className={className}
        sx={{
            'a' : {
                bg : dark ? 'dark' : 'light'
            },
            'a:hover' : {
                bg : dark ? 'primary' : 'secondary'
            }
        }}>
      <li className={style.socialElement}>
        <a
          href='https://www.instagram.com/giordano.betti'
          title='Per il profilo Instagram personale'
          target='_blank'
          rel='noreferrer'
        >
          Instagram
        </a>
      </li>
      <li className={style.socialElement}>
        <a
          href='https://www.facebook.com/profile.php?id=100008190467315'
          title='Per il profilo Facebook personale'
          target='_blank'
          rel='noreferrer'
        >
          Facebook
        </a>
      </li>
      <li className={style.socialElement}>
        <a
          href='https://twitter.com/giordano_betti'
          title='Per il profilo Twitter personale'
          target='_blank'
          rel='noreferrer'
        >
          Twitter
        </a>
      </li>
      <li className={style.socialElement}>
        <a
          href='https://it.linkedin.com/in/giordano-betti-ba1a17171'
          title='Per il profilo LinkedIn personale'
          target='_blank'
          rel='noreferrer'
        >
          LinkedIn
        </a>
      </li>
      <li className={style.socialElement}>
        <a
          href='https://www.youtube.com/channel/UCueBhjrpmY5ZFNdft0M7LAA'
          title='Per il profilo YouTube personale'
          target='_blank'
          rel='noreferrer'
        >
          YouTube
        </a>
      </li>
    </ul>
  )
}

export default SocialList

SocialList.propTypes = {
  className: PropTypes.string,
  dark: PropTypes.bool
}
