/** @jsxImportSource theme-ui */
import React from 'react'

// components
import TransButton from './trans-button'
import Menu from './menu'

// functions
import { useThemeUI } from 'theme-ui'

// style
import '../style/header.scss'

const Header = () => {
  const { colorMode } = useThemeUI()

  return (
    <header className='nav-container'>
      <div className='nav-title'>
        <p className='title'>
          <TransButton to='/' type='none'>
            <em>Giordano Betti</em>
          </TransButton>
        </p>
        <p className='nav-theme'>
          <span>&nbsp; . &nbsp;</span>
          <span sx={{ color: 'primary' }}>
            {colorMode === 'bittersweet'
              ? 'Bittersweet'
              : colorMode === 'viridian'
              ? 'Viridian'
              : 'Caryola'}
          </span>
        </p>
      </div>
      <nav className='nav-desktop'>
        <ul>
          <li>
            <TransButton to='/'>Home</TransButton>
          </li>
          <li>
            <TransButton to='/portfolio'>Portfolio</TransButton>
          </li>
          <li>
            <TransButton to='/contatti'>Contatti</TransButton>
          </li>
        </ul>
      </nav>
      <button className='nav-menu-toggler'>
        <span>Menu</span>
        <span>Chiudi</span>
      </button>
      <Menu />
    </header>
  )
}

export default Header
