/** @jsxImportSource theme-ui */
import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { isBrowser } from 'react-device-detect'

// style
import * as style from '../style/mouse.module.css'

const Mouse = () => {
  const cursor = useRef()
  const [theme, setTheme] = useState(['dark', 'primary'])
  const [pointer, setPointer] = useState(null)
  const state = useRef(null)

  function callback(mutationList) {
    mutationList.forEach((mutation) => {
      switch (mutation.type) {
        case 'attributes':
          state.current = state.current === 'close' ? 'open' : 'close'
          if (state.current === 'open') setTheme(['light', 'secondary'])
          else if (state.current === 'close') setTheme(['dark', 'primary'])
          break
        default:
          break
      }
    })
  }

  useEffect(() => {
    // check if it's server side
    if (isBrowser) {
      const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
      const mouse = { x: pos.x, y: pos.y }
      const speed = 0.35

      gsap.set(cursor.current, {
        xPercent: -50,
        yPercent: -50,
        width: '10px',
        height: '10px',
      })

      const xSet = gsap.quickSetter(cursor.current, 'x', 'px')
      const ySet = gsap.quickSetter(cursor.current, 'y', 'px')

      window.addEventListener('mousemove', (e) => {
        mouse.x = e.x
        mouse.y = e.y

        const computed = window.getComputedStyle(e.target)['cursor']
        if (computed === 'pointer') {
          setPointer(true)
          gsap.to(cursor.current, { scale: 2 })
        } else {
          setPointer(false)
          gsap.to(cursor.current, { scale: 1 })
        }
      })
      // adjust for higher hrz
      gsap.ticker.add(() => {
        const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio())

        pos.x += (mouse.x - pos.x) * dt
        pos.y += (mouse.y - pos.y) * dt
        xSet(pos.x)
        ySet(pos.y)
      })
    }

    // Obsever section
    state.current = 'close'
    const targetNode = document.querySelector('[menu-status]')
    // observe menu for changes
    const observer = new MutationObserver(callback)
    // Start observing the target node for configured mutations
    observer.observe(targetNode, {
      attributes: true,
      attributeFilter: ['menu-status'],
      childList: false,
      subtree: false,
    })
    return () => {
      // stop observing
      observer.disconnect()
    }
  }, [])

  return (
    <div
      ref={cursor}
      className={style.cursor}
      sx={{ bg: pointer ? theme[1] : theme[0] }}
    ></div>
  )
}

export default Mouse
