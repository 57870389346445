import gsap from 'gsap'

export function hideLoader() {
  const loader = document.querySelector('#loader')

  const veil = loader.querySelector('.veil')

  const loaderTl = gsap.timeline({
    defaults: {
      ease: 'expo.in',
      duration: .75,
    },
  })

  loaderTl
    .set(loader, { transformOrigin: 'center right' })
    .set(veil, { transformOrigin: 'center right' })
    .to(veil, { scaleX: 0 })
    .to(loader, { scaleX: 0 }, 0)
}
