import React from 'react'
import PropTypes from 'prop-types'

// style
import * as style from '../style/split-text.module.scss'

function SplitText ({ text, className }) {

  const words = text.split(/(\s+)/)

  const words_div = words.map((word, indexA) => {
    if (word !== ' ') {
      const letters = word.split('')
      const letters_div = letters.map((letter, indexB) => {
        return (
          <span key={`${letter.label}${indexB}`} className={style.letter}>
            {letter}
          </span>
        )
      })

      // check if last element and then remove space at end
      if(Object.keys(words).length - 1 === indexA) {
        return (
          <span className={`${className} ${style.word}`} key={`${word.label}${indexA}`}>
            {letters_div}
          </span>
        )
      } else {
        return (
          <span className={`${className} ${style.word}`} key={`${word.label}${indexA}`}>
            {letters_div}&nbsp;
          </span>
        )
      }
    } else {
      return ''
    }
    // else {
    //   return <span className={style.space} key={`${word.label}${indexA}`}>&nbsp;</span>
    // }
  })

  return words_div
}

SplitText.propTypes = {
  text: PropTypes.node.isRequired
}

export default SplitText
