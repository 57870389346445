/** @jsxImportSource theme-ui */
import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'

// functions
import { useThemeUI } from 'theme-ui'

// functional components
import SplitText from '../functional-components/split-text'

// components
import { StaticImage } from 'gatsby-plugin-image'
import SocialList from '../micro-components/social-list'
import MenuTransButton from '../micro-components/menu-trans-button'
import ContactLink from '../micro-components/contact-link'

// style
import '../style/menu.scss'

const Menu = () => {
  const menu = useRef(null)
  const menuTogglerTl = useRef(null)
  const menuTl = useRef(null)

  const { setColorMode } = useThemeUI()

  // state for changing page
  const [pageChanged, setChange] = useState(false)

  useEffect(() => {
    if (pageChanged) {
      menuTl.current.time(1.25).reverse()
      setChange(false)
    }
  }, [pageChanged])

  useEffect(() => {

    const q = gsap.utils.selector(menu)

    const headerElements = [
      document.querySelectorAll('.nav-menu-toggler span'),
      document.querySelector('.nav-title'),
      document.querySelector('.nav-title p a span')
    ]

    const headerTogglerSpan1 = document.querySelector(
      '.nav-menu-toggler > span:first-child'
    )
    const headerTogglerSpan2 = document.querySelector(
      '.nav-menu-toggler > span:last-child'
    )

    // declare timeline for menu toggler
    menuTogglerTl.current = gsap.timeline({
      paused: true,
      reversed: true,
      defaults: {
        ease: 'expo.inOut',
        duration: 1,
      },
    })
    menuTogglerTl.current.to(
      headerTogglerSpan1,
      {
        yPercent: -100
      },
      0
    )
    .fromTo(
      headerTogglerSpan2,
      {
        yPercent: 100,
      },
      {
        yPercent: -50
      },
      0
    )
    // declare timeline for menu
    menuTl.current = gsap.timeline({
      paused: true,
      reversed: true,
      defaults: {
        ease: 'expo.inOut',
        duration: 1,
      },
      onStart: () => {
        gsap.set(menu.current, { transformOrigin: '50% 0%' })
      },
    })
    // defining timeline for menu
    menuTl.current
      .to(menu.current, {
        scaleY: 1,
      })
      .call(() => {
        gsap.set(menu.current, { transformOrigin: '50% 100%' })
      })
      .to(
        headerElements,
        {
          color: '#e7e5df',
          ease: 'power2.out',
          duration: 0.75,
        },
        0.25
      )
      .fromTo(
        q('.menu-nav .first-span'),
        {
          yPercent: 100,
        },
        {
          yPercent: 0,
          stagger: {
            amount: 0.35,
          },
          ease: 'expo.out',
        },
        0.65
      )
      .to(
        q('.nav-separator'),
        {
          scaleX: 1,
        },
        0.75
      )
      .fromTo(
        q('.menu-nav sub'),
        {
          yPercent: 500,
        },
        {
          yPercent: 0,
          stagger: {
            amount: 0.25,
          },
          ease: 'expo.out',
        },
        0.85
      )
      .fromTo(
        q('.nav-contact-list li > *'),
        {
          yPercent: 150,
        },
        {
          yPercent: 0,
          stagger: {
            amount: 0.25,
          },
          ease: 'expo.out',
        },
        1
      )
      .fromTo(
        q('.menu-theme ul li span'),
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
        },
        1
      )
      .to(
        q('.menu-theme ul li button'),
        {
          scale: 1,
          duration: 0.75,
          stagger: {
            amount: 0.25,
          },
          ease: 'back.out',
        },
        1
      )
      .fromTo(
        q('.menu-footer-info p'),
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 0.75,
          stagger: {
            amount: 0.25,
          },
        },
        1.25
      )
      .fromTo(
        q('.menu-footer-social li a'),
        {
          yPercent: 150,
        },
        {
          yPercent: 0,
          duration: 0.5,
          stagger: {
            amount: 0.25,
          },
          ease: 'expo.out',
        },
        1.25
      )

    // add eventListener click for toggle menu
    document
      .querySelector('.nav-menu-toggler')
      .addEventListener('click', () => {
        if (menuTl.current.reversed()) {
          menuTogglerTl.current.play()
          menuTl.current.play()
          menu.current.setAttribute('menu-status', 'open')
        } else {
          menuTogglerTl.current.reverse()
          menuTl.current.reverse()
          menu.current.setAttribute('menu-status', 'close')
        }
      })

    // select all theme button and add eventListener
    gsap.utils.toArray('.menu-theme button').forEach((button) => {
      const tl = gsap.timeline({
        paused: true,
        defaults: {
          duration: 0.75,
          ease: 'back.out',
        },
      })

      tl.to(button, {
        scale: 1.2,
      })

      button.addEventListener('mouseenter', () => tl.play())
      button.addEventListener('mouseleave', () => tl.reverse())
    })
  }, [])

  return (
    <div ref={menu} className='menu-container' menu-status='close'>
      <div className='menu-theme'>
        <ul>
          <li>
            <span>Cambia tema </span>
          </li>
          <li>
            <button
              onClick={() => {
                setColorMode('bittersweet')
              }}
              style={{ backgroundColor: '#ed6a5e' }}
            >
              Bittersweet
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setColorMode('caryola')
              }}
              style={{ backgroundColor: '#8783d1' }}
            >
              Caryola
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setColorMode('viridian')
              }}
              style={{ backgroundColor: '#119da4' }}
            >
              Viridian
            </button>
          </li>
        </ul>
      </div>
      <nav className='menu-nav'>
        <ul className='nav-main-list'>
          <li>
            <MenuTransButton to='/' ref={menuTogglerTl} state={pageChanged} setState={setChange}>
              <SplitText text='home' className='first-span' />
              <SplitText
                text='home'
                className='last-span'
                sx={{ color: 'secondary' }}
              />
              <sub>. 01</sub>
            </MenuTransButton>
          </li>
          <li>
            <MenuTransButton
              to='/portfolio'
              ref={menuTogglerTl}
              state={pageChanged}
              setState={setChange}
            >
              <SplitText text='portfolio' className='first-span' />
              <SplitText
                text='portfolio'
                className='last-span'
                sx={{ color: 'secondary' }}
              />
              <sub>. 02</sub>
            </MenuTransButton>
          </li>
          <li>
            <MenuTransButton
              to='/contatti'
              ref={menuTogglerTl}
              state={pageChanged}
              setState={setChange}
            >
              <SplitText text='contatti' className='first-span' />
              <SplitText
                text='contatti'
                className='last-span'
                sx={{ color: 'secondary' }}
              />
              <sub>. 03</sub>
            </MenuTransButton>
          </li>
        </ul>
        <div className='nav-separator' sx={{bg:'primary'}}></div>
        <ul className='nav-contact-list'>
          <li>
            <StaticImage 
              src='../images/icon-phone.png'
              alt='Icona telefono per il link'
              className='contact-img'
            />
            <ContactLink
              to='tel: +39 331 891 2895'
              title='Chiama Giordano Betti'
              dark={true}
            >
              +39 331 891 2895
            </ContactLink>
          </li>
          <li>
            <StaticImage 
              src='../images/icon-mail.png'
              alt='Icona mail per il link'
              className='contact-img'
            />
            <ContactLink
              to='mailto: contact@giordanobetti.com'
              title='Invia una mail a Giordano Betti'
              dark={true}
            >
              contact@giordanobetti.com
            </ContactLink>
          </li>
        </ul>
      </nav>
      <div className='menu-footer'>
        <div className='menu-footer-info'>
          <p>P.iva 01520740521</p>
          <p>
            <a
              href='https://www.iubenda.com/privacy-policy/89672123'
              title='Pagina privacy policy di Iubenda'
              target='_blank'
              rel='noreferrer'
              sx={{ color : '#e7e5dfbc', '&:hover': { color: 'secondary' } }}
            >
              Privacy / Cookie Policy
            </a>
          </p>
        </div>
        <SocialList className='menu-footer-social' dark={false} />
      </div>
      <div className='menu-load-allert'>
        <span className='loader-perc'></span>
      </div>
    </div>
  )
}

export default Menu
